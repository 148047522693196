app.constant('ERROR_CODES', {
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
    OK: 'OK',
    UNAUTHORIZED: 'UNAUTHORIZED',
});

app.constant('QUERY_TYPE', {
    TERM_HISTOGRAM: 'TERM_HISTOGRAM',
    TIME_HISTOGRAM: 'TIME_HISTOGRAM',
    TERM_LIST: 'TERM_LIST',
});

app.constant('EVENT_CODES', {
    LOGIN_SUCCESS: 'auth-login-success',
    LOGIN_FAILED: 'auth-login-failed',
    LOGOUT_SUCCESS: 'auth-logout-success',
    SESSION_TIMEOUT: 'auth-session-timeout',
    NOT_AUTHENTICATED: 'auth-not-authenticated',
    NOT_AUTHORIZED: 'auth-not-authorized',
    USER_DATA_CHANGED: 'user-data-changed',
    PASSWORD_CHANGED: 'user-password-changed',
});

app.constant('GAME_PLATFORM', {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE',
    MINIGAME: 'MINIGAME',
});

app.constant('USER_ROLES', {
    ADMIN: 'ADMIN',

    ALARMS_UPDATE: 'ALARMS_UPDATE',
    ALARMS_VIEW: 'ALARMS_VIEW',

    MARKETING_MATERIALS_VIEW: 'MARKETING_MATERIALS_VIEW',
    MARKETING_MATERIALS_UPDATE: 'MARKETING_MATERIALS_UPDATE',

    GAME_SESSIONS_VIEW: 'GAME_SESSIONS_VIEW',

    DATA_PACKAGES_DOWNLOAD: 'DATA_PACKAGES_DOWNLOAD',
    DATA_PACKAGES_REGENERATE: 'DATA_PACKAGES_REGENERATE',

    INVOICING_REPORT: 'INVOICING_REPORT',
    CASINO_GAME_PERFORMANCE_REPORT: 'CASINO_GAME_PERFORMANCE_REPORT',
    FREE_ROUNDS_CAMPAIGN_COST_REPORT: 'FREE_ROUNDS_CAMPAIGN_COST_REPORT',
    PLAYER_PERFORMANCE_REPORT: 'PLAYER_PERFORMANCE_REPORT',
    CASINO_PERFORMANCE_PER_DAY_REPORT: 'CASINO_PERFORMANCE_PER_DAY_REPORT',
    CASINO_PERFORMANCE_PER_DAY_DETAILED_REPORT: 'CASINO_PERFORMANCE_PER_DAY_DETAILED_REPORT',
    PLAYER_DEMOGRAPHIC_DATA_REPORT: 'PLAYER_DEMOGRAPHIC_DATA_REPORT',
    USERS_LIST_REPORT: 'USERS_LIST_REPORT',
    GAME_ROUND_FEATURES_PER_PLAYER_REPORT: 'GAME_ROUND_FEATURES_PER_PLAYER_REPORT',

    PLAYERS_SEARCH: 'PLAYERS_SEARCH',

    JACKPOTS_VIEW: 'JACKPOTS_VIEW',

    GAME_MANAGEMENT: 'GAME_MANAGEMENT',

    REPORTING_VIEW: 'REPORTING_VIEW', //TODO rename to ANALYTICS

    CASINO_GAME_MANAGEMENT_VIEW: 'CASINO_GAME_MANAGEMENT_VIEW',
    CASINO_GAME_MANAGEMENT_UPDATE: 'CASINO_GAME_MANAGEMENT_UPDATE',

    FREE_ROUND_CAMPAIGNS_VIEW: 'FREE_ROUND_CAMPAIGNS_VIEW',
    FREE_ROUND_CAMPAIGNS_UPDATE: 'FREE_ROUND_CAMPAIGNS_UPDATE',

    GSMC_FREE_ROUND_CAMPAIGNS_UPDATE: 'GSMC_FREE_ROUND_CAMPAIGNS_UPDATE',

    SESSION_WALLET_VIEW: 'SESSION_WALLET_VIEW',
    SESSION_WALLET_UPDATE: 'SESSION_WALLET_UPDATE'
});

app.constant('CASINO_TYPES', {
    CASINO: 'CASINO',
    GROUP: 'GROUP'
});

app.constant('GROUP_TYPES', {
    GENERIC: 'GENERIC',
    CASINO_GROUP: 'CASINO_GROUP',
    CASINO_INTEGRATOR: 'CASINO_INTEGRATOR'
});

app.constant('GLOBAL_CONSTANTS', {
    ROOT_CASINO_ID: -1,
    CURRENCY_EUR_ID: 1,
    MANAGED_FREEROUNDS: false,
    ELLIPSIS_LENGTH: 50
});

app.constant('CAMPAIGN_CONSTANTS', {
    DEFUALT_FR_DAYS_TO_WAGER: 30
});

app.constant('ALARM_CONSTANTS', {
    EMA_RTP: "Rtp",
    HIGH_WIN: "High Win"
});

app.constant('IP_TYPES', {
    SIMPLE: "SIMPLE",
    REGULAR_EXP: "REGULAR_EXP",
    SUBNET: "SUBNET"
});

app.constant('GAME_ROUND_STATE', {
    ALLOCATING_FUNDS: "ALLOCATING_FUNDS",
    INSUFFICIENT_FUNDS: "INSUFFICIENT_FUNDS",
    FUNDS_STRUCTURE_CHANGED: "FUNDS_STRUCTURE_CHANGED",
    CANCELING_GAME_ROUND: "CANCELING_GAME_ROUND",
    CANCELED: "CANCELED",
    PLAYING: "PLAYING",
    WINNING_JACKPOT: "WINNING_JACKPOT",
    RETURNING_FUNDS: "RETURNING_FUNDS",
    FINISHED: "FINISHED"
});

app.constant('REPORT_CONSTANTS', {
    MAX_ROWS_TO_DISPLAY: 200
});
